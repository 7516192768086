/* Alex Foxleigh's CSS Reset (based on YUI2) */

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;

  &::before,
  &::after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    padding: 0;
  }
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset,
img {
  border: 0;
  outline: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

body:not(:-moz-handler-blocked) fieldset {
  display: table-cell;
}

legend {
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
  font-style: normal;
  font-weight: normal;
}

ol,
ul {
  list-style: none;
}

p ol {
  list-style: decimal;
}

p ul {
  list-style-type: disc;
}

caption,
th {
  text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

q::before,
q::after {
  content: '';
}

a {
  outline: 0;
  text-decoration: none;
}

abbr,
acronym {
  border: 0;
}

header,
article,
section,
nav,
figure,
aside,
hgroup,
footer {
  display: block;
} // Allow HTML5 elements in older browsers
body,
html {
  height: 100%;
  margin: 0;
  padding: 0;
}

html,
html a {
  -webkit-font-smoothing: antialiased !important;
}

select::-ms-expand {
  display: none;
} // Remove default drop down style in IE
html {
  // Reset font size to 10px
  font-size: 62.5%;
}

body {
  min-height: 100%;
}

strong {
  font-weight: bold;
}

p em {
  font-style: italic;
} /* Any text inside a paragraph should be formatted normally */

input,
button {
  border: 0;
}

hr {
  border: 0;
  border-top: 1px solid #444;
  margin: 1em 0;
}
